import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import parse from "html-react-parser"
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
// import { Disqus } from 'gatsby-plugin-disqus'

// We're using Gutenberg so we need the block styles
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactFormWrapper from '../components/ContactFormWrapper'

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  const featuredImage = {
    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: post.featuredImage?.node?.alt || ``,
  }
  const classes = useStyles();
  const author=  post.author
  const authorName = author.node.firstName ? `${author.node.firstName} ${author.node.lastName}` : author.node.name
  const avatarUrl = author?.node?.avatar?.url
  // console.log(previous, next)
  // const siteUrl = 'https://beta.geogo.in'
  // let disqusConfig = {
  //   url: `${siteUrl+post.uri}`,
  //   identifier: post.id,
  //   title: post.title,
  // }

  return (
    <Layout>
      <Seo title={post.title} description={post.excerpt} />
      <section className="alignwide">
        <Grid container spacing={5} justify="center">
          <Grid item xs={12} lg={9}>
            <article
              className="blog-post"
              itemScope
              itemType="http://schema.org/Article"
            >
              <header>
                <h2 itemProp="headline" className={classes.heading}>{parse(post.title)}</h2>
                <span className={classes.authorName}>{authorName}</span>
                <span className={classes.separator}>•</span>
                <span className={classes.postDate}>{post.date}</span>
                <br />
                <br />
                {/* if we have a featured image for this post let's display it */}
                {featuredImage?.fluid && (
                  <Image
                    fluid={featuredImage.fluid}
                    alt={featuredImage.alt}
                    style={{ marginBottom: 50 }}
                  />
                )}
              </header>

              {!!post.content && (
                <section itemProp="articleBody" className={classes.description}>{parse(post.content)}</section>
              )}
              <div className={classes.clearfix} />

              <footer>
                <div className={classes.bio}>
                  {avatarUrl && (
                    <img
                      alt={author?.firstName || ``}
                      src={avatarUrl}
                      className={`${classes.avatar} bio-avatar`}
                    />
                  )}
                  <div>
                    <span className={classes.writtenBy}>Written by </span>
                    <h5 className={classes.writerName}>{authorName}</h5>
                    {` `}
                    {author?.node?.description || null}
                    {` `}
                    {author?.twitter && (
                      <a href={`https://twitter.com/${author?.twitter || ``}`}>
                        You should follow them on Twitter
                      </a>
                    )}
                  </div>
                </div>
              </footer>
            </article>
            <div className={classes.clearfix} />
            {/* <h3>Comments</h3>            */}
            {/* <CommentCount config={disqusConfig} placeholder={'...'} /> */}
            {/* Post Contents */}
            {/* <Disqus config={disqusConfig} /> */}
            <h3>More from our blog</h3>           
            <div>
              {previous && (
                <div className={classes.lineCard}>
                  <Link to={previous.uri} rel="prev" className={`${classes.navLink} move-child-icon-to-right`}>
                    <h3 className={classes.lineCardTitle}>{parse(previous.title)}</h3>
                    <span className={classes.lineCardDesc}>{parse(previous.excerpt)}</span>
                    <TrendingFlatIcon className={`${classes.whiteIcon} move-right-icon`} />
                  </Link>
              </div>
              )}
              {next && (
                <div className={classes.lineCard}>
                  <Link to={next.uri} rel="next" className={`${classes.navLink} move-child-icon-to-right`}>
                    <h3 className={classes.lineCardTitle}>{parse(next.title)}</h3>
                    <span className={classes.lineCardDesc}>{parse(next.excerpt)}</span>
                    <TrendingFlatIcon className={`${classes.whiteIcon} move-right-icon`} />
                  </Link>
                </div>
              )}
            </div>
            <br />
            {/* <h3>Hello</h3> */}
          </Grid>
        </Grid>            
      </section>
      <ContactFormWrapper linkTo='careers' />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      author {
        node {
          name
          firstName
          lastName
          description
          avatar {
            url
          }
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
      excerpt
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
      excerpt
    }
  }
`

const useStyles = makeStyles((theme) => ({
  // h2: {
  //   fontSize: 30
  // },
  heading: {
    // fontSize: '2.6rem',
    // margin: '48px 0px 8px 0',
    // [theme.breakpoints.down('xs')]: {
    //   fontSize: '1.8rem',
    // },
  },
  description: {
    fontSize: 18
  },
  authorName: {
    fontSize: 16,
    color: '#0d850d',
    textTransform: 'capitalize'
  },
  separator: {
    color: '#c8c8c8',
    margin: '0 8px',
    fontSize: 12
  },
  postDate: {
    color: '#757575',
    fontSize: 14
  },
  bio: {
    display: 'flex',
    borderTop: '1px solid rgba(230, 230, 230, 1)',
    borderBottom: '1px solid rgba(230, 230, 230, 1)',
    marginTop: 25,
    padding: '25px 0'
  },
  avatar: {
    borderRadius: '50%',
    marginRight: 24
  },
  writtenBy: {
    textTransform: 'uppercase',
    display: 'block',
    color: '#6f6f6f',
    fontWeight: 400
  },
  writerName: {
    fontSize: 22,
    fontWeight: 500,
    margin: '5px 0 10px 0'
  },
  navLink: {
    fontSize: 18,
    textDecoration: 'none'
  },
  lineCard: {
    border: '1px solid #DFDFDF',
    width: '100%',
    display: 'flex',
    marginBottom: 12,
    padding: '16px 24px 2px 24px'
  },
  lineCardTitle: {
    color: '#161616',
    marginTop: 0
  },
  lineCardDesc: {
    color: '#6f6f6f',
    fontSize: 16
  },
  whiteIcon: {
    fill: '#161616',
    fontSize: '2.5rem',
    position: 'relative'
  },
  clearfix: {
    clear: 'both'
  }
}));